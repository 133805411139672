import * as React from "react";
import { Collapse } from "reactstrap";
import {
    UWG5NventThermostat,
    UWG5NventSettings, Errors, DisplaySettings as DisplaySettingsModel,
    RegulationSettings as RegulationSettingsModel,
    HeatingSettings as HeatingSettingsModel,
    FloorSensorSettings as FloorSensorSettingsModel,
    AwayModeSettings as AwayModeSettingsModel,
    DateTimeSettings as DateTimeSettingsModel,
    WifiSettings as WifiSettingsModel,
    WarrantyInfo as WarrantyInfoModel,
    ModeType,
    DeviceInfo as DeviceInfoModel, SensorApplication,
} from "../../store/UWG5NventStore/models";
import { UWG5NventSettingsComponent } from "./UWG5NventSettings";
import { UWG5NventAction } from "../../store/UWG5NventStore/actions";
import { SwimlaneColourDot } from "../ColourDot";
import { DateTimePicker } from "@mui/lab";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AlertType } from "../../store/AlertStore/models";
import { popupAlert } from "../../Utility";
import { TextField } from "@mui/material";
import { DisplaySettings } from "./DisplaySettings";
import { RegulationSettings } from "./RegulationSettings";
import { HeatingSettings } from "./HeatingSettings";
import { FloorSensorSettings } from "./FloorSensorSettings";
import { AwayModeSettings } from "./AwayModeSettings";
import { DateTimeSettings } from "./DateTimeSettings";
import { WifiSettings } from "./WifiSettings";
import { UWG5NventSchedule } from "./UWG5NventSchedule";
import { WarrantyInfo } from "./WarrantyInfo";
import { DeviceInfo } from "./DeviceInfo";
import { FaWifi, FaLink, FaUnlink } from "react-icons/fa";
import { TbPlugConnected } from "react-icons/tb";

type Props = {
    thermostat: UWG5NventThermostat,
    invokeAction(action: UWG5NventAction): void,
    setSetpoint(setpoint: number): void,
    setAutoMode(): void,
    setManualMode(setpoint: number): void,
    setHoldMode(setpoint: number, endTime: Date | null, isPermanent: boolean | null): void,
    cancelHoldMode(): void,
    updateSettings(settings: UWG5NventSettings): void,
    updateDeviceInfo(deviceInfo: DeviceInfoModel): void,
    updateDisplaySettings(settings: DisplaySettingsModel): void,
    updateRegulationSettings(settings: RegulationSettingsModel): void,
    updateHeatingSettings(settings: HeatingSettingsModel): void,
    updateFloorSensorSettings(settings: FloorSensorSettingsModel): void,
    updateAwayModeSettings(settings: AwayModeSettingsModel): void,
    updateDateTimeSettings(settings: DateTimeSettingsModel): void,
    updateWifiSettings(settings: WifiSettingsModel): void,
    updateWarrantyInfo(info: WarrantyInfoModel): void,
    setCurrentScheduleEvent(day: number, eventId: number): void,
    setErrors(errors: Errors): void,
    setStandbyState(isInStandby: boolean): void
}

enum SettingsToDisplay {
    None,
    GeneralSettings,
    DisplaySettings,
    DeviceInfo,
    RegulationSettings,
    HeatingSettings,
    FloorSensorSettings,
    DateTimeSettings,
    WifiSettings,
    Schedule,
    AwayMode,
    WarrantyInfo
}

export const UWG5NventSwimlane = (props: Props) => {
    // Component state mapped from props
    const [thermostat, setThermostat] = React.useState(props.thermostat);
    const dispatch = useDispatch();

    // Update state when props changed
    useEffect(() => setThermostat(props.thermostat), [props.thermostat]);

    // Internal state
    const [showHoldTime, setShowHoldTime] = React.useState(false);
    const [wantedSetPoint, setWantedSetPoint] = React.useState("");
    const [wantedEndTime, setWantedEndTime] = React.useState<Date | null>(thermostat.mode.holdUntilEndTime);
    const [settingsToDisplay, setSettingsToDisplay] = React.useState(SettingsToDisplay.None);

    const SettingsToggle = (props: { text: string, s: SettingsToDisplay }) => {
        const isActive = settingsToDisplay === props.s;
        const color = isActive ? "active" : "";

        return (
            <li className="nav-item">
                <button
                    className={"btn btn-sm btn-link nav-link " + color}
                    onClick={() => setSettingsToDisplay(isActive ? SettingsToDisplay.None : props.s)}>{props.text}</button>
            </li>
        );
    };

    const currMode = thermostat.mode;

    const currentTemperature = props.thermostat.regulationSettings.sensorApplication === SensorApplication.Floor ?
        props.thermostat.state.floorTemperature : props.thermostat.state.roomTemperature;
    const currentTemperatureFahrenheit = props.thermostat.regulationSettings.sensorApplication === SensorApplication.Floor ?
        props.thermostat.state.floorTemperatureFahrenheit : props.thermostat.state.roomTemperatureFahrenheit;

    /**
     * Save wanted set point.
     */
    const saveWantedSetpoint = () => {
        const sanitizedSetPoint = (wantedSetPoint || "").trim().replace(",", ".");
        if (sanitizedSetPoint !== "") {
            const setPoint = parseFloat(sanitizedSetPoint);
            props.setSetpoint(setPoint);
            setWantedSetPoint("");
        }
    };

    const defaultLocale = "en-US";

    const formatHoldTime = (datetime: Date | string) => {
        const today = new Date();
        const date = new Date(datetime);
        const isToday = date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
        const customFormat = isToday ?
            new Intl.DateTimeFormat(defaultLocale, {
                hour: "2-digit",
                minute: "2-digit"
            }) :
            new Intl.DateTimeFormat(defaultLocale, {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit"
            });

        return customFormat.format(date);
    };

    const getModeLabel = (): string => {
        if (!currMode) {
            return "Undefined";
        }

        const modeType = currMode.currentMode;
        switch (modeType) {
            case ModeType.Auto:
                return "Auto";
            case ModeType.Manual:
                return "Manual";
            case ModeType.Hold:
                if (currMode.holdIsPermanent) {
                    return "Permanent hold";
                } else if (!currMode.holdUntilEndTime) {
                    return "Hold until next event";
                } else {
                    return `Hold until ${formatHoldTime(currMode.holdUntilEndTime)}`;
                }
        }

        return "Undefined";
    };

    const getSetpointLabel = (): string => {
        let setpoint = "N/A";
        const modeType = currMode.currentMode;
        switch (modeType) {
            case ModeType.Auto:
                return "Auto";
            case ModeType.Manual:
                setpoint = currMode.manualSetpoint.toString();
                break;
            case ModeType.Hold:
                setpoint = currMode.holdUntilSetpoint!.toString();
                break;
        }

        return `${setpoint}°C`;
    };

    const parseSetpointOrDefault = (setpoint: string) => {
        const sanitizedSetPoint = (setpoint).trim().replace(",", ".");

        // default in case of empty
        let parsed: number = 10;

        if (sanitizedSetPoint !== "") {
            parsed = parseFloat(sanitizedSetPoint);
        }

        return parsed;
    };

    const setAuto = () => {
        setShowHoldTime(false);
        props.setAutoMode();
    };

    const setManual = () => {
        setShowHoldTime(false);
        const setpoint = parseSetpointOrDefault(wantedSetPoint);
        props.setManualMode(setpoint);
    };

    const setPermanentHold = () => {
        setShowHoldTime(false);
        const setpoint = parseSetpointOrDefault(wantedSetPoint);
        props.setHoldMode(setpoint, null, true);
    };

    const setHoldUntilFromNowTillNextEvent = () => {
        setShowHoldTime(false);
        const setpoint = parseSetpointOrDefault(wantedSetPoint);
        props.setHoldMode(setpoint, null, false);
    };

    const submitHoldWithTime = () => {
        if (!wantedEndTime) {
            return;
        }

        setShowHoldTime(false);
        const setpoint = parseSetpointOrDefault(wantedSetPoint);
        props.setHoldMode(setpoint, wantedEndTime, false);
    };

    const toggleTimer = () => {
        if (props.thermostat.isTimerEnabled) {
            popupAlert(dispatch, "Timer stopped.", "Room temperature is no longer automated.", AlertType.SUCCESS);
        } else {
            popupAlert(dispatch, "Timer started.", "Room temperature should update every five seconds.", AlertType.SUCCESS);
        }

        props.invokeAction(UWG5NventAction.ToggleTimer);
    };

    const isLinked = !!props.thermostat.settings.accessCode || !!props.thermostat.settings.otpCode;
    const iconBaseClass = "ml-2 mb-n1 w-50 drop-shadow-sm ";

    // Render
    return (
        <div className="bg-white rounded-lg shadow-sm border mb-2 p-2">
            {/* Header */}
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    {SwimlaneColourDot(props.thermostat.deviceInfo.serialId)}
                    {props.thermostat.deviceInfo.thermostatName}
                    <FaWifi
                        className={ iconBaseClass + (props.thermostat.isConnected ? "text-success" : "text-danger") }
                        data-toggle="tooltip"
                        data-placement="top"
                        title={props.thermostat.isConnected ? "Connected" : "Disconnected"} />
                    {
                        isLinked ?
                            <FaLink
                                className={ iconBaseClass + "text-success" }
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Linked" /> :
                            <FaUnlink
                                className={ iconBaseClass + "text-danger" }
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Unlinked"/>
                    }

                </div>
                <div className="d-flex align-items-center">
                    <svg width="1.1em" height="1.1em" fill="currentColor" className="bi bi-save2 btn-outline-secondary float-right mt-1 mr-1" viewBox="0 0 16 16" color="gray" onClick={() => {
                        props.invokeAction(UWG5NventAction.Save);
                        popupAlert(dispatch, "Thermostat saved!", "You have successfully saved the thermostat.", AlertType.SUCCESS);
                    }}>
                        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v4.5h2a.5.5 0 0 1 .354.854l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5A.5.5 0 0 1 5.5 6.5h2V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                    </svg>
                    <svg width="1.1em" height="1.1em" viewBox="0 0 16 16" className="bi bi-x-square btn-outline-danger float-right mt-1 mr-1" fill="currentColor" color="red" onClick={() => props.invokeAction(UWG5NventAction.Remove)}>
                        <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path fillRule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                        <path fillRule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                    </svg>
                </div>
            </div>

            {/* Summary */}
            <div className="my-2">
                <div className="d-flex justify-content-between">
                    <div><b>Serial Id: </b>{props.thermostat.deviceInfo.serialId}</div>
                    <div><b>Mode: </b>{getModeLabel()}</div>
                    <div><b>Set point: </b>{getSetpointLabel()}</div>
                    <div><b>Current temp: </b>{currentTemperature}&deg;C / {currentTemperatureFahrenheit}&deg;F</div>
                </div>
            </div>

            {/* Actions */}
            <div className="my-2">
                <div className="d-flex justify-content-between">
                    <div className="btn-group mr-2">
                        {props.thermostat.isConnected ?
                            <button
                                className="btn btn-sm btn-outline-danger"
                                onClick={() => props.invokeAction(UWG5NventAction.Disconnect)}>
                                Disconnect
                            </button>
                            :
                            <button
                                className="btn btn-sm btn-outline-success"
                                onClick={() => props.invokeAction(UWG5NventAction.Connect)}>
                                Connect
                            </button>
                        }

                        <button
                            title="Toggle reconnect"
                            className={`btn btn-sm ${props.thermostat.reconnectEnabled ? "btn-success" : "btn-outline-danger"}`}
                            onClick={() => props.invokeAction(UWG5NventAction.ToggleReconnect)}>
                            <TbPlugConnected />
                        </button>
                    </div>

                    <button
                        title="Toggle heat relay"
                        className={`btn btn-sm ${props.thermostat.state.isHeatRelayActive ? "btn-danger" : "btn-outline-secondary"}`}
                        onClick={() => props.invokeAction(UWG5NventAction.ToggleHeatRelay)}>
                        <svg className="mt-n1" width={16} height={16} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                            <path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                        </svg>
                    </button>

                    <div className="btn-group mx-2">
                        <button
                            onClick={() => props.invokeAction(UWG5NventAction.Link)}
                            disabled={isLinked}
                            className="btn btn-sm btn-outline-primary">Link</button>
                        <button
                            onClick={() => props.invokeAction(UWG5NventAction.Unlink)}
                            disabled={!isLinked}
                            className="btn btn-sm btn-outline-primary">Unlink</button>
                    </div>

                    <div className="input-group mr-2">
                        <div className="input-group-prepend">
                            <button
                                onClick={setAuto}
                                className="btn btn-sm btn-outline-primary text-truncate">Auto</button>
                            <button
                                onClick={setManual}
                                className="btn btn-sm btn-outline-primary text-truncate">Manual</button>
                            <button
                                onClick={setPermanentHold}
                                className="btn btn-sm btn-outline-primary text-truncate">Permanent</button>
                            <button
                                onClick={() => setShowHoldTime(!showHoldTime)}
                                className="btn btn-sm btn-outline-primary text-truncate">Hold until</button>
                            <button
                                onClick={setHoldUntilFromNowTillNextEvent}
                                className="btn btn-sm btn-outline-primary text-truncate">Hold until next</button>
                            <button
                                onClick={() => props.invokeAction(UWG5NventAction.DecreaseSetpoint)}
                                className="btn btn-sm btn-outline-primary">
                                <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M112 184l144 144 144-144" />
                                </svg>
                            </button>
                            <button
                                onClick={() => props.invokeAction(UWG5NventAction.IncreaseSetpoint)}
                                className="btn btn-sm btn-outline-primary">
                                <svg className="mt-n1" width={16} height={16} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M112 328l144-144 144 144" />
                                </svg>
                            </button>
                        </div>
                        <input
                            type="text"
                            placeholder="Set point..."
                            className="form-control form-control-sm border-primary"
                            value={wantedSetPoint}
                            onChange={(e) => setWantedSetPoint(e.target.value)} />
                        <div className="input-group-append">
                            <button
                                onClick={() => saveWantedSetpoint()}
                                disabled={wantedSetPoint === ""}
                                className="btn btn-sm btn-outline-primary">
                                <svg className="mt-n1" width={16} height={16} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M470.3 271.15L43.16 447.31a7.83 7.83 0 01-11.16-7V327a8 8 0 016.51-7.86l247.62-47c17.36-3.29 17.36-28.15 0-31.44l-247.63-47a8 8 0 01-6.5-7.85V72.59c0-5.74 5.88-10.26 11.16-8L470.3 241.76a16 16 0 010 29.39z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                </svg>
                            </button>
                            <button
                                onClick={() => toggleTimer()}
                                className={`btn btn-sm ${thermostat.isTimerEnabled ? "btn-primary" : "btn-outline-primary"}`}>
                                <svg className="mt-n1" width={16} height={16} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M112.91 128A191.85 191.85 0 0064 254c-1.18 106.35 85.65 193.8 192 194 106.2.2 192-85.83 192-192 0-104.54-83.55-189.61-187.5-192a4.36 4.36 0 00-4.5 4.37V152" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    <path d="M233.38 278.63l-79-113a8.13 8.13 0 0111.32-11.32l113 79a32.5 32.5 0 01-37.25 53.26 33.21 33.21 0 01-8.07-7.94z" fill="currentColor" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="btn-group">
                        <button
                            onClick={() => props.invokeAction(UWG5NventAction.FactoryReset)}
                            className="btn btn-sm btn-outline-danger text-truncate">Factory reset</button>
                    </div>
                </div>
            </div>

            {/* Hold time prompt */}
            <Collapse isOpen={showHoldTime} className="container mb-2">
                <div className="row">
                    <div className="col">
                        <DateTimePicker
                            ampm={false}
                            onError={console.error}
                            clearable
                            renderInput={(props) => <TextField {...props} />}
                            disablePast
                            inputFormat="yyyy/MM/dd HH:mm"
                            mask={"____/__/__ __:__"}
                            label="Hold until"
                            value={wantedEndTime}
                            onChange={date => setWantedEndTime(date)} />
                    </div>
                    <div className="col-sm">
                        <svg onClick={submitHoldWithTime}
                            fill={wantedEndTime ? "currentColor" : "gray"}
                            width="25px" height="1.7em" viewBox="0 0 16 16" className="bi bi-check btn-outline-primary border border-primary rounded align-top mt-3">
                            <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                        </svg>
                    </div>
                </div>
            </Collapse>

            {/* Settings toggles */}
            <ul className="nav nav-tabs nav-fill border-bottom-0">
                <SettingsToggle text="General" s={SettingsToDisplay.GeneralSettings} />
                <SettingsToggle text="Device" s={SettingsToDisplay.DeviceInfo} />
                <SettingsToggle text="Display" s={SettingsToDisplay.DisplaySettings} />
                <SettingsToggle text="Regulation" s={SettingsToDisplay.RegulationSettings} />
                <SettingsToggle text="Heating" s={SettingsToDisplay.HeatingSettings} />
                <SettingsToggle text="FloorSensor" s={SettingsToDisplay.FloorSensorSettings} />
                <SettingsToggle text="DateTime" s={SettingsToDisplay.DateTimeSettings} />
                <SettingsToggle text="Wifi" s={SettingsToDisplay.WifiSettings} />
                <SettingsToggle text="Schedule" s={SettingsToDisplay.Schedule} />
                <SettingsToggle text="Away" s={SettingsToDisplay.AwayMode} />
                <SettingsToggle text="Warranty" s={SettingsToDisplay.WarrantyInfo} />
            </ul>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.GeneralSettings}>
                <UWG5NventSettingsComponent isConnected={thermostat.isConnected}
                    errorState={thermostat.state.errorState}
                    isInStandby={thermostat.standbyState.isInStandby}
                    settings={thermostat.settings}
                    saveSettings={props.updateSettings}
                    setErrors={props.setErrors}
                    setStandbyState={props.setStandbyState}/>
            </Collapse>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.DeviceInfo}>
                <DeviceInfo
                    deviceInfo={thermostat.deviceInfo}
                    isThermostatConnected={thermostat.isConnected}
                    saveDeviceInfo={props.updateDeviceInfo} />
            </Collapse>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.DisplaySettings}>
                <DisplaySettings
                    displaySettings={thermostat.displaySettings}
                    saveDisplaySettings={props.updateDisplaySettings} />
            </Collapse>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.RegulationSettings}>
                <RegulationSettings
                    regulationSettings={thermostat.regulationSettings}
                    saveRegulationSettings={props.updateRegulationSettings} />
            </Collapse>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.HeatingSettings}>
                <HeatingSettings
                    heatingSettings={thermostat.heatingSettings}
                    saveHeatingSettings={props.updateHeatingSettings} />
            </Collapse>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.FloorSensorSettings}>
                <FloorSensorSettings
                    floorSensorSettings={thermostat.floorSensorSettings}
                    saveFloorSensorSettings={props.updateFloorSensorSettings} />
            </Collapse>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.DateTimeSettings}>
                <DateTimeSettings
                    dateTimeSettings={thermostat.dateTimeSettings}
                    saveDateTimeSettings={props.updateDateTimeSettings} />
            </Collapse>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.WifiSettings}>
                <WifiSettings
                    wifiSettings={thermostat.wifiSettings}
                    saveWifiSettings={props.updateWifiSettings} />
            </Collapse>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.Schedule}>
                <UWG5NventSchedule schedule={thermostat.schedule} setCurrentScheduleEvent={props.setCurrentScheduleEvent} />
            </Collapse>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.AwayMode}>
                <AwayModeSettings
                    awayModeSettings={thermostat.awayModeSettings}
                    saveAwayModeSettings={props.updateAwayModeSettings} />
            </Collapse>

            <Collapse isOpen={settingsToDisplay === SettingsToDisplay.WarrantyInfo}>
                <WarrantyInfo
                    warrantyInfo={thermostat.warrantyInfo}
                    saveWarrantyInfo={props.updateWarrantyInfo} />
            </Collapse>
        </div>
    );
};